import {
  autoPlacement,
  computePosition,
  ComputePositionConfig,
  flip,
  FloatingOverlay,
  shift,
  size,
} from '@floating-ui/react-dom-interactions';
import {
  createContext,
  ForwardedRef,
  ReactElement,
  ReactNode,
  Ref,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { State, useHookstate } from '@hookstate/core';
import { Portal } from '~/components/interactive/Portals';

export interface PopupProps {
  children: ReactElement;
  activator?: ReactNode;
  strategy?: 'bottom' | 'best' | 'bottom-left' | 'right';
  open?: boolean | State<boolean>;
  reference?: Ref<ReactNode> | HTMLDivElement | Ref<HTMLElement> | ForwardedRef<HTMLDivElement>;
  onClose?: () => void;
  className?: string;
  lightbox?: boolean;
  rerender?: string;
  autowidth?: boolean;
  offset?: number;
  hover?: number | true;
}

const context = createContext<{ recalculate: () => void } | null>(null);

export function usePopupContext() {
  const ctx = useContext(context);

  if (!ctx) {
    throw new Error('usePopupContext must be used within a <Popup />');
  }

  return ctx;
}

export default function Popup({
  activator,
  children,
  open,
  reference,
  onClose,
  lightbox = false,
  autowidth = false,
  strategy = 'best',
}: PopupProps) {
  const scope = useHookstate(open || false);
  const isOpen = typeof open === 'boolean' ? open : !activator && typeof open === 'undefined' ? true : scope.get();
  const calculated = useHookstate(false);
  const [hold, setHold] = useState(false);

  useEffect(() => {
    const metaDown = (e: KeyboardEvent) => {
      if (isOpen && e.metaKey) {
        setHold(true);
      }
    };

    const metaUp = (e: KeyboardEvent) => {
      if (hold) {
        setHold(false);
      }
    };

    window.addEventListener('keydown', metaDown);
    window.addEventListener('keyup', metaUp);

    return () => {
      window.removeEventListener('keydown', metaDown);
      window.removeEventListener('keyup', metaUp);
    };
  }, [isOpen, hold]);

  const doClose = () => {
    calculated.set(false);

    if (typeof open === 'boolean' || typeof open === 'undefined') {
      if (onClose) {
        onClose();
      }
    } else {
      scope.set(false);
    }
  };

  const ref = useRef<HTMLDivElement>();
  const activatorRef = useRef<HTMLDivElement>(null);

  const calculate = useCallback(() => {
    const target = reference
      ? reference instanceof HTMLDivElement
        ? HTMLDivElement
        : (reference as RefObject<any>).current
      : activatorRef.current;

    if (!ref.current || !target || !(target instanceof Element)) {
      return;
    }

    let options: Partial<ComputePositionConfig> = {
      middleware: [
        autoPlacement({
          allowedPlacements: [
            'left',
            'right',
            'top',
            'bottom',
          ],
        }),
        shift(),
      ],
    };

    if (strategy === 'bottom') {
      options = {
        placement: 'bottom',
        middleware: [
          flip(),
        ],
      };
    } else if (strategy === 'bottom-left') {
      options = {
        placement: 'bottom-start',
        middleware: [
          flip(),
        ],
      };
    }

    if (autowidth) {
      options.middleware?.push(
        size({
          apply({ rects }) {
            if (ref.current) {
              Object.assign(ref.current.style, {
                width: `${rects.reference.width}px`,
              });
            }
          },
        }),
      );
    }

    computePosition(target, ref.current, options).then(({ x, y }) => {
      if (ref.current) {
        Object.assign(ref.current.style, {
          top: `${y}px`,
          left: `${x}px`,
        });
      }
    });
  }, [reference, strategy, autowidth]);

  let center = '';

  if (!activator && !reference) {
    center = 'flex items-center justify-center';
  }

  const overlay = useRef<HTMLDivElement>(null);

  const portal = (isOpen || hold) && (
    <Portal>
      <context.Provider value={{ recalculate: calculate }}>
        <FloatingOverlay
          ref={overlay}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className={`${lightbox ? 'bg-black bg-opacity-25' : hold ? '' : 'pointer-events-none'} ${center} z-100 ${
            hold ? '' : 'select-none'
          }`}
          onMouseDownCapture={(e) => {
            if (hold) {
              e.stopPropagation();
            }
          }}
          onContextMenu={(e) => {
            if (e.target === overlay.current) {
              e.preventDefault();

              // make sure we are not closing if clicking children
              doClose();
            }
          }}
          onClick={
            lightbox
              ? (e) => {
                  if (e.target === overlay.current) {
                    e.stopPropagation();

                    // make sure we are not closing if clicking children
                    doClose();
                  }
                }
              : undefined
          }
        >
          <div
            ref={(el) => {
              ref.current = el || undefined;

              calculate();
            }}
            className="absolute"
          >
            {children}
          </div>
        </FloatingOverlay>
      </context.Provider>
    </Portal>
  );

  if (activator) {
    return (
      <>
        <div
          ref={activatorRef}
          onMouseEnter={() => scope.set(true)}
          onMouseLeave={() => scope.set(false)}
          onClick={() => scope.set(false)}
        >
          {activator}
        </div>
        {portal}
      </>
    );
  }

  return <>{portal}</>;
}
